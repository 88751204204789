import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div className='home'>
      <div className='home-section-container'>
        <section className='home-section'>
          <Link to={'/Books'}>
            <h1>Bortvald som barn</h1>
            <div className='img-container'>
              <img src={require('../images/bortvald_omslag_fram.jpg')} alt="Bild på bildomslag till boken 'Bortvald som barn'." />
            </div>
          </Link>
          <a href='https://www.vistoforlag.se/bok/bortvald-som-barn/' aria-label='Beställ boken via förlagets webshop.' target='_blank' rel="noreferrer"><h2>Beställ boken här</h2></a>
        </section>

        <section className='home-section'>
          <Link to={'/Books'}>
            <h1>Sårbarhet och livskraft</h1>
            <div className='img-container'>
              <img src={require('../images/bok2.jpg')} alt="Bild på bildomslag till boken 'Sårbarhet och livskraft'." />
            </div>
          </Link>
          <a href='https://www.vistoforlag.se/bok/sarbarhet-och-livskraft/' aria-label='Beställ boken via förlagets webshop.' target='_blank' rel="noreferrer"><h2>Beställ boken här</h2></a>
        </section>  
      </div>
      
      
      <aside className='home-upcoming'>
        <h2>Aktuellt</h2>
        <p>Andra boken i serien om Karin, "Sårbarhet och livskraft", finns nu ute till försäljning. <Link to={'/books'}>Mer information om boken här.</Link></p>
        <p><strong>2024-11-12:</strong> Västerås arbetarförening, damklubben, författarbesök med bokpresentation, kl. 19, Västerås.</p>
        <p><strong>2024-11-26:</strong> Uppläsningskväll kl. 17-19 på Stadsbiblioteket, Västerås. Västmanlands Författarsällskap (författaruppläsning).</p>
      </aside>
    </div>
  )
}

export default Home